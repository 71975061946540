import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkAndAddToCart } from "../../store/cartSlice";
import {
  getGroupProductTotalPrice,
  getVarients,
} from "../../services/woocommerceServices/Products";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { AppDispatch } from "../../store";
import { uniqueId } from "lodash";
import Link from "../Link";

interface props {
  product: any;
  isShowAddToCartBtn?: boolean;
}
const ProductCard: FC<props> = ({ product, isShowAddToCartBtn }) => {
  // console.log(product.grouped_products ,"sadjh")
  const { userId } = useJWTChecker();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [price, setPrice] = useState<any | undefined>();

  // const [review, setReview] = useState<any>(false);

  // useEffect(() => {
  // 	axios.get(`https://api-cdn.yotpo.com/products/32WCW8rg0gbhiSy1IHEZjRuKH0UynB7Va83EdmlP/${product.wordpress_id}/bottomline`).then((data)=>{
  // 		console.log("data.data.response.bottomline",data.data.response.bottomline)
  // 		setReview(data.data.response.bottomline)

  // 	}).catch((err)=>{
  // 		setReview({average_score:0, total_reviews:0})
  // 	})
  // }, [])

  const dispatch = useDispatch<AppDispatch>();
  const handelAddtocart = async (product: any, quantity: number = 1) => {

    const productId = product.wordpress_id ? product.wordpress_id : product.id;
    setDisabled(true);
    setLoading(true);
    const varientDetails = await getVarients(productId);
    const obj = {
      id: uniqueId(),
      productId,
      name: product.name,
      slug: product.slug,
      price: Number(product.price),
      thumbnail: product.images[0]?.src,
      variant: varientDetails[0],
      quantity: quantity,
      categories: product.categories,
    };
    try {
      // const stockStatus: any = await CheckStock(
      //   productId,
      //   Number(quantity + 1)
      // );
      // if (stockStatus.instock) {
      await dispatch(
        checkAndAddToCart({ item: obj, userId: userId, quantity: Number(1) })
      );

      // toast.success("Item added to cart");
      setLoading(false);
      setDisabled(false);
      // } else {
      //   setLoading(false);
      //   setDisabled(false);
      //   toast.error("Product outof stock. ");
      // }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleClick = (prod: any) => {
    navigate(`/products/${prod.slug}`+ location.search, {
      state: {
        productId: prod.wordpress_id,
        categoryName: prod.categories?.[0].name,
        categorySlug: prod.categories?.[0].slug,
        productName: prod?.name,
      },
    });
  };

  useEffect(() => {
    if (product?.grouped_products?.length > 0) {
      getGroupProductTotalPrice(product.grouped_products).then((data: any) => {
        setPrice(data.total)
      }).catch((err) => {
        console.log(err)
      })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPrice((Number(product?.price) || 0).toFixed(2));
    }
  }, []);

  const nav = (path: string, query: string) => {
    return `${path}/${query}`;
  };

  return (
    <div
      className={`lg:rounded-[21px] md:min-w-[353px] min-w-[280px] rounded-[12px] p-4 bg-bg-grey lg:hover:bg-white lg:hover:shadow-xl lg:transition-shadow lg:duration-300 h-auto mb-4 product-list`}
    >
      <Link to={`/products/${product.slug}`}  preserveQueryParams={true}>
        <div className="flex items-start h-auto  justify-between ">
          <div className="flex-col flex  gap-1">
            {!product.manage_stock ||
              (product.manage_stock &&
                product.stock_status == "instock" &&
                product.stock_quantity > 0) ? (
              <>
                <p className="text-[#225403] font-sofiaMedium font-medium md:text-[14px] text-[12px]">
                  In stock 
                </p>
              </>
            ) : (
              <>
                <p className="text-primary font-sofiaMedium font-medium md:text-[14px] text-[12px]">
                  Out of stock
                </p>
              </>
            )}
          </div>
          <p className="md:text-[14px] text-[12px] font-sofiaRegular font-regular text-[#383838] leading-[1.2]">
            {/* ${(Number(product?.price) || 0).toFixed(2)} */}${price}
          </p>
        </div>

        {/* <Review reviewData={3}/>Review{review.total_reviews} */}
        <div className="md:h-[290px] h-[150px] relative w-full mx-auto  cat-pro">
          <LazyLoadImage
            effect="blur"
            onClick={() => handleClick(product)}
            src={
              product?.images[0]?.src
                ? product?.images[0]?.src
                : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`
            }
            alt={product?.name}
            // width={116}
            // height={243}
            className="md:h-[300px] h-[150px] w-full object-contain rounded"
          />
          {/* <img
          // src={Pro}
          onClick={() => handleClick(product)}
          src={
          product?.images[0]?.src
            ? product?.images[0]?.src
            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZrYKVr0m8q69FuWmt0zSuRWnalD87DVuiwg&s"
          }
          alt={product?.name}
          width={116}
          height={243}
          className="h-[243px] w-full object-contain rounded"
        /> */}
        </div>
        <h3 className=" flex justify-center items-center lg:text-[16px] slg:text-[18px] mb-3 lg:min-h-[50px] md:min-h-[80px] min-h-[40px] md:mt-[-47px] mt-[-10px] text-center md:text-[16px] text-[14px]  font-sofiaRegular font-regular text-black leading-[1.2]">
          {product?.name}
        </h3>
        <div className="flex gap-2 justify-between items-center mt-4">
          {!product.manage_stock ||
            (product.manage_stock &&
              product.stock_status == "instock" &&
              product.stock_quantity > 0) ? (
            <Link
              disabled={disabled}
              className="bg-black text-white whitespace-nowrap md:px-4 px-1 py-2 rounded-[21px] font-sofiaRegular font-regular text-center md:text-[16px] text-[12px] hover:bg-primary transition-colors duration-300 w-1/2"
              to={`/products/${product.slug}`}  preserveQueryParams={true}
            >
              {loading ? "Adding.." : "Buy now"}
            </Link>
          ) : (
            <button
              disabled={true}
              className={
                "bg-gray-400 cursor-not-allowed text-white whitespace-nowrap md:px-4 px-1 py-2 rounded-[21px] font-sofiaRegular font-regular md:text-[16px] text-[12px] w-1/2"
              }
            >
              Out of Stock
            </button>
          )}
          <button
            onClick={() => handleClick(product)}
            className="bg-white border border-gray-300 whitespace-nowrap rounded-[21px] !text-black font-sofiaRegular font-regular md:text-[16px] text-[12px] md:px-4 px-1 py-2  hover:bg-primary hover:!text-white transition-colors duration-300 w-1/2"
          >
            Learn more
          </button>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
